import React from "react";
import styles from "./AboutSection.module.css";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Button } from "../../components/UI/Form/Button/Button";
import * as routeConstant from "../../common/RouteConstants";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

const AboutSection = () => {
    const history = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const backToList = () => {
        try{   
            history(routeConstant.CLIENT);
        }catch(err){
            showBoundary(err);
        }
    }
    return (
    <Grid>
        <Grid item xs={2} className={styles.FilterAddWrap}>
          <div className={styles.ButtonGroup2}>
            <Button
              id="cancel-button"
              className={styles.BackToButton}
              variant={"contained"}
              onClick={backToList}
              color="secondary"
              data-testid="cancel-button"
            >
              <img
                src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
                alt="user icon"
              />
              &nbsp; Back to Home
            </Button>
          </div>
        </Grid>
        <Paper className={styles.paper}>
            <div>
              <div className={styles.header}>ABOUT OB360</div>
              <p className={styles.content}>
                OB360 is CyberGuard360’s latest suite of advanced security tools for MSPs of any size.  
                Includes Pen Testing, Vulnerability Assessment, and Advanced Vulnerability Assessment. 
                Using these tools, you may perform security scanning, vulnerability assessment, 
                network scanning, web scanning, technical assessment, remediation, and recommendations.
              </p>
              <div className={styles.subHeader}>In OB360, we have three types of scans:</div>
              <p className={styles.content}>
                <b>1. Vulnerability Assessment: </b> The Vulnerability assessment scans the system 
                against a known set of vulnerabilities or NVTs that are updated regularly in the system. 
                This identifies the version of the os/libraries/files etc that are found at the location 
                scanned and looks up the lists of known vulnerabilities in the NVT database for a match 
                and lists those that are found and the possible solution which could mean an upgrade to a 
                higher version that has fixed the vulnerability. It does not do any kind of actual 
                penetration test to validate the vulnerability.
              </p>
              <p className={styles.content}>
                <b>2. Advanced Vulnerability : </b> Advanced VA’s (Internal Vulnerability Assessment) 
                are for internal scans only and do not scan WAN IP’s. The VPN connection does have 
                all the details necessary for the connection and no WAN IP is needed. You can use 
                this scans as a black box vulnerability assessment without logging into the machines 
                or provide the credentials and do a white box scan with the administrator credentials.
              </p>
              <p className={styles.content}>
                <b>3. Automated Penetration Testing: </b>The automated Penetration Test scans the machine 
                locations provided for loopholes in the system that allow hackers to gain access to the system. 
                So it tries various attack payloads and reports only those that are successfully validated as accessible.
              </p>
              <p className={styles.content}>
                <b>Help Section :</b> https://access.piisecured.com/help/
              </p>
            </div>
        </Paper>
    </Grid>
    )
}

export default AboutSection;