import React, { useState, useEffect } from "react";
import styles from "./RaReportListing.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Grid } from "@mui/material";
import AutoCompleteDropDown from "../../../components/UI/Form/Autocomplete/Autocomplete";
import SyncIcon from "@mui/icons-material/Sync";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  GET_REPORT_LISTING,
  GET_REPORT_LISTING_STATUS,
} from "../../../graphql/queries/ReportListing";
import * as routeConstant from "../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { RA_REPORT_DOWNLOAD } from "../../../config/index";
import { saveAs } from "file-saver";
import { ZIP_FILE } from "../../../graphql/mutations/Upload";
import { PUBLISH_REPORT } from "../../../graphql/mutations/PublishReport";
import PublishIcon from "@mui/icons-material/Publish";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import Switch from "../../../components/UI/Switch/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
// import { DELETE_TARGET_FROM_LIST } from "../../../graphql/mutations/Target";
import { SOFT_DELETE } from "../../../graphql/mutations/Target";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import AlertBox from "../../../components/UI/AlertBox/AlertBox";
import * as msgConstant from "../../../common/MessageConstants";
import logout from "../../Auth/Logout/Logout";
import Cookies from "js-cookie";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import ComputerIcon from "@mui/icons-material/Computer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DehazeSharpIcon from "@mui/icons-material/DehazeSharp";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Tooltip from "@mui/material/Tooltip";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

export const RaReportListing: React.FC = (props: any) => {
  const { showBoundary } = useErrorBoundary();
  const history = useNavigate();
  const location = useLocation();
  const session = Cookies.getJSON("ob_session");
  let moment = require("moment-timezone");
  const [param, setParam] = useState<any>(
    location?.state ? location.state[Object.keys(location.state)[0]] : null
  );
  const [published, setPublished] = useState<any>({});
  const [backdrop, setBackdrop] = useState<Boolean>(false);
  const [showBackdrop, setShowBackdrop] = useState<Boolean>(true);
  const [selectedFile, setSelectedFile] = useState<any>({});

  const [newData, setNewData] = useState([]);
  const partner = JSON.parse(localStorage.getItem("partnerData") || "{}");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  let userRole: any;
  if (user) {
    userRole = user.isSuperuser == true ? "SuperUser" : "CompanyUser";
  }
  //table
  const [targetOpen, setTargetOpen] = React.useState(false);
  const handleTargetToolTipClose = () => {
    setTargetOpen(false);
  };

  const handleTargetToolTipOpen = () => {
    setTargetOpen(true);
  };
  const [selectedRow, setSelectedRow] = useState(null);
  const sortByDate = (a: any, b: any, order: string | undefined) => {
    if (!a.scansheduledDate) return 1;
    if (!b.scansheduledDate) return -1;

    const dateA = new Date(a.scansheduledDate);
    const dateB = new Date(b.scansheduledDate);

    if (order === "asc") {
      return dateA > dateB ? 1 : -1;
    }
    if (order === "desc") {
      return dateA < dateB ? 1 : -1;
    }

    return 0;
  };

  const sortByDateReport = (a: any, b: any, order: string | undefined) => {
    if (!a.reportCreationDate) return 1;
    if (!b.reportCreationDate) return -1;

    const dateA = new Date(a.reportCreationDate);
    const dateB = new Date(b.reportCreationDate);

    if (order === "asc") {
      return dateA > dateB ? 1 : -1;
    }
    if (order === "desc") {
      return dateA < dateB ? 1 : -1;
    }

    return 0;
  };
  const CompnyUserColumns = [
    // { title: "Company Name", key: "clientName" },
    { title: "Scan Name", key: "target" },
    { title: "Scan Type", key: "scanType" },
    {
      title: "Status",
      key: "status",
      icon: (item: any) => (
        // item.status === "Scan Completed" ? (
        <div>
          <Tooltip
            // open={targetOpen}
            // onClose={handleTargetToolTipClose}
            // onOpen={handleTargetToolTipOpen}
            placement="right"
            title={
              <React.Fragment>
                <p>
                  <b className={styles.tooltiptext}> {item.details} </b>{" "}
                </p>{" "}
              </React.Fragment>
            }
          >
            <ContactSupportIcon className={styles.CircleIcon2} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Scan Scheduled",
      key: "scansheduledDate",
      customSort: (a: any, b: any, order: string | undefined) =>
        sortByDate(a, b, order === "asc" ? "desc" : "asc"),
    },
    {
      title: "Report Generated",
      key: "reportCreationDate",
      customSort: (a: any, b: any, order: string | undefined) =>
        sortByDateReport(a, b, order === "asc" ? "desc" : "asc"),
    },
    // {
    //   key: "makeACopy",
    //   title: "",
    //   icon: (rowData: any) => (rowData?.scanType === "Advanced VA" ? <SyncIcon /> : <></>),
    //   tooltip: "Make a copy",
    //   displayIcon: (rowData: any) => rowData?.scanType === "Advanced VA" ,
    // },
    {
      key: "download",
      title: "",
      icon: <GetAppIcon />,
      tooltip: "Download",
      disable: (rowData: any) => !(rowData?.status === "Report Generated"),
      displayIcon: (rowData: any) => true,
    },
    {
      key: "delete",
      title: "",
      icon: (
        <img
          className={styles.EditIcon}
          src={process.env.PUBLIC_URL + "/icons/svg-icon/delete.svg"}
          alt="delete icon"
        />
      ),
      tooltip: "Delete",
      disable: (rowData: any) => !(rowData?.status === "Report Generated" || rowData?.status === "Scheduled-Recurring" || rowData?.status === "Queued" || rowData?.status === "Failed"),
      displayIcon: (rowData: any) => true,
    },
  ];
  const AdminColumns = [
    { title: "Scan Name", key: "target" },
    { title: "Status", key: "status" },
    { title: "Report Status", key: "report_status" },
    {
      key: "download",
      title: "",
      icon: <GetAppIcon />,
      tooltip: "Download",
      displayIcon: (rowData: any) => true,
    },
    {
      key: "download",
      title: "",
      icon: (rowData: any) =>
        !rowData.publish ? (
          <div>
            <input
              type="file"
              name={rowData.targetId}
              id={rowData.targetId}
              className={styles.uploadButton}
              hidden
              onChange={(event: any) => {
                setSelectedFile({
                  [rowData.targetId]: event.target.files[0],
                });
              }}
            />
            <label htmlFor={rowData.targetId}>
              <CloudUploadIcon />
            </label>
          </div>
        ) : (
          <CloudUploadIcon
            style={{
              fill: "grey",
              position: "relative",
              top: "10px",
            }}
          />
        ),
      tooltip: "Browse",
    },
    {
      key: "upload",
      title: "",
      icon: (rowData: any) =>
        rowData.publish ? (
          <PublishIcon
            style={{
              fill: "grey",
              position: "relative",
              top: "10px",
            }}
          />
        ) : (
          <PublishIcon />
        ),
      tooltip: "Upload",
    },
    {
      key: "publish",
      title: "",
      icon: (rowData: any) => (
        <div className={styles.raswitch}>
          <Button
            color="primary"
            variant={"contained"}
            data-testid="ok-button"
            className={styles.PublishButton}
            disabled={rowData.publish}
          >
            Publish
          </Button>
        </div>
      ),
      tooltip: "Publish",
      //   disabled: rowData.publish,
      //   onClick: (event: any, rowData: any) => {
      //     handlePublishchange(event, rowData);
      //   },
      // }),
    },
  ];
  const columns = partner.partnerId ? CompnyUserColumns : AdminColumns;
  const title = "Listing of Reports";
  const [orderBy, setOrderBy] = useState<String>();
  //static values
  const propsClientName =
    param && param.clientInfo ? param.clientInfo.name : undefined;
  const propsClientId =
    param && param.clientInfo ? parseInt(param.clientInfo.clientId) : undefined;
  const clientInfo = param ? param.clientInfo : undefined;
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [targetDeleted, SetTargetDeleted] = useState(false);
  const [rowData2, setRowData] = useState<any>({});
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [dialogBoxMsg, setDialogBoxMsg] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  //filter query condition declaration
  const [
    getReportListingData,
    { data: dataReportListing, loading: loadingReportListing },
  ] = useLazyQuery(GET_REPORT_LISTING_STATUS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setShowBackdrop(false);
      createTableDataObject(data.getTargetStatus);
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
      // logout();
    },
  });

  const [uploadFile] = useMutation(ZIP_FILE);
  const [publishReport] = useMutation(PUBLISH_REPORT);
  const [deleteTarget] = useMutation(SOFT_DELETE);

  useEffect(() => {
    if (Cookies.getJSON("ob_session")) {
      getReportListingData({
        variables: {
          clientname: propsClientName,
          clientid: propsClientId ? propsClientId : parseInt(clientInfo.clientId),
        },
      });
      if (param && param.formState) {
        setFormState((formState) => ({
          ...formState,
          isSuccess: param.formState.isSuccess,
          isUpdate: param.formState.isUpdate,
          isDelete: param.formState.isDelete,
          isFailed: param.formState.isFailed,
          errMessage: param.formState.errMessage,
        }));
      }
    } else {
      logout();
    }
  }, []);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    getReportListingData({
      variables: {
        clientname: propsClientName,
        clientid: propsClientId ? propsClientId : parseInt(clientInfo.clientId),
      },
    });
  }, [targetDeleted]);

  function convertDate(inputFormat: any) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }
  const getDateAndTime = (utcDate: any) => {
    if (utcDate === "" || utcDate === null) {
      return null;
    } else {
      var dateFormat: any = new Date(utcDate);
      var hours = dateFormat.getHours();
      var minutes = dateFormat.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      var dateAndTime = convertDate(new Date(utcDate)) + " " + strTime;
      return dateAndTime;
    }
  };

  const createTableDataObject = (data: any) => {
    let arr: any = [];
    if (partner.partnerId == undefined) {
      data.forEach((element: any) => {
        if (
          element.status == "Generating Report" ||
          element.status === "Report Generated"
        ) {
          let obj: any = {};
          obj["targetId"] = element.targetId !== 0 ? element.targetId : null;
          obj["host"] = element.host;
          obj["target"] = element.targetName;
          element.scanType === "Pentest"
            ? (obj["scanType"] = "Pen Test")
            : element.scanType === "Advanced"
              ? (obj["scanType"] = "Advanced VA")
              : element.scanType === "External"
                ? (obj["scanType"] = "External VA")
                : (obj["scanType"] = element.scanType);
          obj["status"] = element.status;
          obj["publish"] =
            element.publishedFlag == "Unpublished" ? false : true;
          obj["report_status"] = element.publishedFlag;
          obj["clientName"] = element.clientName;
          arr.push(obj);
        }
      });
    }
    if (partner.partnerId != undefined) {
      data.forEach((element: any) => {
        let obj: any = {};
        obj["targetId"] = element.targetId !== 0 ? element.targetId : null;
        obj["host"] = element.host;
        obj["target"] = element.targetName;
        element.scanType === "Pentest"
          ? (obj["scanType"] = "Pen Test")
          : element.scanType === "Advanced"
            ? (obj["scanType"] = "Advanced VA")
            : element.scanType === "External"
              ? (obj["scanType"] = "External VA")
                : element.scanType === "MAIN EDGE"
                ? (obj["scanType"] = "EDGE")
                 : (obj["scanType"] = element.scanType);
        if(element.status === "Scheduled"){
          obj["status"] = "Queued";
        }else{
          obj["status"] = element.status;
        }
        // obj["status"] = element.status;
        obj["scansheduledDate"] = moment(element.startDate)
          .tz(partner.partnerId.tZone)
          .format("MM/DD/YYYY hh:mm A");
        if (element.status === "Report Generated") {
          obj["reportCreationDate"] = moment(element.reportCreationDate)
            .tz(partner.partnerId.tZone)
            .format("MM/DD/YYYY hh:mm A");
        }
        obj["publish"] = element.publishedFlag == "Unpublished" ? false : true;
        obj["report_status"] = element.publishedFlag;
        obj["clientName"] = element.clientName;
        if (element.status === "Scheduled"){
          obj["details"] = msgConstant.Queued;
        }if(element.status === "Scheduled-Recurring") {
          obj["details"] = msgConstant.Scheduled;
        }
        if (element.status === "Scan Completed") {
          obj["details"] = msgConstant.ScanCompleted;
        }
        if (element.status === "Generating Report") {
          obj["details"] = msgConstant.GeneratingReport;
        }
        if (element.status === "Result Generated") {
          obj["details"] = msgConstant.ResultGenerated;
        }
        if (element.status === "Report Generated") {
          obj["details"] = msgConstant.ReportGenerated;
        }
        if (element.status === "Failed") {
          obj["details"] = msgConstant.TestFailed;
        }
        if (element.status === "In Progress") {
          obj["details"] = msgConstant.InProgress;
        }
        obj["targetnameTimestamp"] = element.targetnameTimestamp;
        arr.push(obj);
      });
    }
    arr.sort((a: any, b: any) => {
      if (!a.scansheduledDate) return 1;
      if (!b.scansheduledDate) return -1;
      return (
        new Date(b.scansheduledDate).valueOf() -
        new Date(a.scansheduledDate).valueOf()
      );
    });
    arr.sort((a: any, b: any) => {
      if (!a.reportCreationDate) return 1;
      if (!b.reportCreationDate) return -1;
      return (
        new Date(b.reportCreationDate).valueOf() -
        new Date(a.reportCreationDate).valueOf()
      );
    });
      // Custom sorting function for "status"
    const statusOrder:any = {
      "Queued": 1,
      "Scheduled": 2,
      "Scan Completed": 3,
      "In Progress": 4,
      "Generating Report": 5,
      // "Result Generated": 4,
      "Report Generated": 6,
      "Failed": 7,
      // "In Progress": 7,
    };

    arr.sort((a: any, b: any) => {
      const statusA = a.status === "Scheduled-Recurring" ? a.status.slice(0,9):a.status;
      const statusB = b.status === "Scheduled-Recurring" ? b.status.slice(0,9):b.status;
      
      // Compare statuses based on the custom order
      return statusOrder[statusA] - statusOrder[statusB];
    });

    setNewData(arr);
  };

  const onRowClick = (rowData: any, param: any) => {
    if (param === "upload") {
      handleUpload(rowData);
    }
    if (param === "download") {
      handleDownload(rowData);
    }
    if (param === "upload") {
      handleUpload(rowData);
    }
    if (param === "makeACopy") {
      handleClickOpen(rowData);
    }
    if (param === "delete") {
      handleClickDelete(rowData);
    }
    if (param === "publish") {
      handlePublishchange(rowData);
    }
  };

  const handleClickView = (rowData: any) => {
    if (Cookies.getJSON("ob_session")) {
      history(routeConstant.REPORT_STATUS, {
        state: {
          targetName: rowData.targetnameTimestamp,
          clientInfo: clientInfo,
        },
      });
    } else {
      logout();
    }
  };

  const handleClickOpen = (rowData: any) => {
    history(routeConstant.TARGET, {
      state: {
        data: {
          reRun: true,
          targetName: rowData.target,
          targetData: rowData,
          clientInfo: clientInfo,
        },
      },
    });
  };

  const handleDownload = (rowData: any) => {
    handleAlertClose();
    try{
    if (Cookies.getJSON("ob_session")) {
      setBackdrop(true);
      let intTargetId = parseInt(rowData.targetId);
      const DocUrl = `${RA_REPORT_DOWNLOAD}?cid=${propsClientId}&tid=${intTargetId}`;
      fetch(DocUrl, {
        method: "GET",
      })
        .then((response: any) => {
          response.blob().then((blobData: any) => {
            saveAs(blobData, rowData.target.replace(/[^a-zA-Z0-9]/g, ""));
            setBackdrop(false);
          });
        })
        .catch((err:any) => {
          sentry_error_catch(err,setShowBackdrop,setFormState)
        });
    } else {
      logout();
    }
  }catch(error){
    showBoundary(error)
  }
  };

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (err:any) {
        sentry_error_catch(err,setShowBackdrop,setFormState)
      };
    }
  };

  const handleUpload = (rowData: any) => {
    if (selectedFile[rowData.targetId]) {
      setBackdrop(true);
      let idCardBase64 = "";
      getBase64(selectedFile[rowData.targetId], (result: any) => {
        idCardBase64 = result;
        var res = result.slice(result.indexOf(",") + 1);
        uploadFile({
          variables: {
            input: {
              client: parseInt(param.clientInfo.clientId),
              targetName: rowData.target,
              file: res,
              targetId: rowData.targetId,
            },
          },
        })
          .then((response: any) => {
            if (
              response.data.uploadZipFile.success === "File Uploaded Failed"
            ) {
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_FILE_UPLOAD_FAILED,
              }));
              setSelectedFile({});
              setBackdrop(false);
            } else {
              setFormState((formState) => ({
                ...formState,
                isSuccess: true,
                isUpdate: false,
                isDelete: false,
                isFailed: false,
                errMessage: "File Uploaded Successfully !!",
              }));
              setSelectedFile({});
              setBackdrop(false);
            }
          })
          .catch((err: Error) => {
            sentry_error_catch(err,setShowBackdrop,setFormState)
          });
      });
    }
  };

  const handlePublishchange = (rowData: any) => {
    setBackdrop(true);
    publishReport({
      variables: {
        input: {
          client: parseInt(param.clientInfo.clientId),
          targetName: rowData.target,
          // partner: param.clientInfo.partnerId,
          flagStatus: true,
          id: rowData.targetId,
        },
      },
    })
      .then((response: any) => {
        setBackdrop(false);
        if (
          response.data.publishedReport.success ===
          "Report Published Successfully "
        ) {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: "Report Published Successfully !!",
          }));
          getReportListingData({
            variables: {
              clientname: propsClientName,
              clientid: propsClientId ? propsClientId : parseInt(clientInfo.clientId),
            },
          });
          setSelectedFile({});
        } else {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: " Report Un-Published Successfully !!",
          }));
        }
      })
      .catch((err: any) => {
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const handleAddNewReport = () => {
    handleAlertClose();
    let data = {};
    data = { clientInfo: clientInfo };
    history(routeConstant.EXTERNAL_TARGET, { state: { data } });
  };

  const handleAddNewAdvanceReport = () => {
    handleAlertClose();
    let data = {};
    data = { clientInfo: clientInfo };
    history(routeConstant.TARGET, { state: { data } });
  };
  const handleAddNewPentest = () => {
    handleAlertClose();
    let data = {};
    data = { clientInfo: clientInfo };
    history(routeConstant.PEN_TEST, { state: { data } });
  };

  const handleAddEdgeScan = () => {
    handleAlertClose();
    let data = {};
    data = { clientInfo: clientInfo };
    history(routeConstant.EDGE_SCAN, { state: { data } });
  };

  // const handleAddNewAdvancedPentest = () => {
  //   handleAlertClose();
  //   let data = {};
  //   data = { clientInfo: clientInfo };
  //   history(routeConstant.ADVANCED_PEN_TEST, { state: { data } });
  // };

  const orderFunc = (orderedColumnId: any, orderDirection: any) => {
    let orderByColumn;
    let orderBy = "";
    if (orderedColumnId >= 0) {
      if (columns[orderedColumnId]["key"] === "name") {
        orderByColumn = "name";
      }
    }
    orderBy = orderByColumn + ":" + orderDirection;
    setOrderBy(orderBy);
  };

  const handleClickDelete = (rowData: any) => {
    handleAlertClose();
    setShowBackdrop(true);
    setOpenDialogBox(true);
    setDialogBoxMsg(msgConstant.SOFT_DELETE_POPUP_MSG + rowData.target + "?");
    setRowData(rowData);
  };

  const confirmDelete = async () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      let userData = JSON.parse(Cookies.getJSON("ob_user"));
      closeDialogBox();
      SetTargetDeleted(false);
      let input = {
        user: parseInt(partner.userId["id"]),
      };
      deleteTarget({
        variables: {
          id: rowData2.targetId,
          firstName: userData.data.getUserDetails.edges[0].node.firstName,
          lastName: userData.data.getUserDetails.edges[0].node.lastName,
          type: "Cancel",
          token: session,
          input,
          target_status: rowData2.status === "Scheduled-Recurring" ? rowData2.status : ""
        },
      })
        .then((res: any) => {
          setShowBackdrop(false);
          if (res.data.softDelete.status === "Target Deleted Successfully") {
            if (propsClientName != undefined) {
              getReportListingData({
                variables: {
                  clientname: propsClientName,
                  clientid: propsClientId ? propsClientId : parseInt(clientInfo.clientId),
                },
              });
            }
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: true,
              isFailed: false,
              errMessage: "  " + rowData2.target + "  ",
            }));
          }
          if (res.data.softDelete.status === "Target Not Deleted") {
            setShowBackdrop(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.SOFT_DELETE_FAILED + rowData2.target + " ",
            }));
          }
        })
        .catch((err:any) => {
          sentry_error_catch(err,setShowBackdrop,setFormState)
        });
    } else {
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };
  const closeDialogBox = () => {
    setShowBackdrop(false);
    setOpenDialogBox(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {propsClientName}
      </Typography>
      <Grid>
        {loadingReportListing || backdrop || showBackdrop ? (
          <SimpleBackdrop />
        ) : null}
        <DialogBox
          open={openDialogBox}
          handleOk={confirmDelete}
          handleCancel={closeDialogBox}
          buttonOk={"Yes"}
          buttonCancel={"No"}
          classes={{
            root: styles.MainOfficeDialogRoot,
            container: styles.MainOfficeDialogboxContainer,
            paper: styles.MainOfficeDialogboxPaper,
            scrollPaper: styles.MainOfficeScrollPaper,
          }}
        >
          <div className={styles.DialogBoxTitle}>
            <Typography component="h1" variant="h1">
              Please Confirm
            </Typography>
          </div>
          <div className={styles.DialogBoxContext}>
            <p>{dialogBoxMsg}</p>
          </div>
        </DialogBox>

        <Grid container className={styles.backToListButtonPanel}>
          <Grid item xs={12} md={12} className={styles.backToListButton}>
            <Button
              className={styles.BackToButton}
              variant={"contained"}
              onClick={() => {
                let data = {};
                data = { clientInfo: clientInfo };
                history(routeConstant.CLIENT, { state: { data } });
              }}
              color="secondary"
              data-testid="cancel-button"
            >
              <img
                src={process.env.PUBLIC_URL + "/icons/svg-icon/back-list.svg"}
                alt="user icon"
              />
              &nbsp; Back to List
            </Button>
            {/* ) : null} */}
            {partner.partnerId ? (
              <>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  className={styles.ActionButton}
                >
                  <AddCircleIcon className={styles.CircleIcon} />
                  &nbsp; CREATE TEST
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className={styles.MenuButton}
                  classes = {{paper:styles.MuiMenuPaper}}
                >
                  <MenuItem onClick={handleAddNewReport}>
                    {" "}
                    &nbsp; External Vulnerability
                  </MenuItem>
                  <MenuItem onClick={handleAddNewAdvanceReport}>
                    {" "}
                    &nbsp; Advanced Vulnerability
                  </MenuItem>
                  <MenuItem onClick={handleAddNewPentest}>
                    {" "}
                    &nbsp; Pen Test
                  </MenuItem>
                  {/* <MenuItem onClick={handleAddNewAdvancedPentest}>
                    {" "}
                    &nbsp; Advanced Pen Test
                  </MenuItem> */}
                  <MenuItem onClick={handleAddEdgeScan}>
                    {" "}
                    &nbsp; EDGE
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Paper className={styles.paper}>
          <Grid item xs={12} className={styles.AlertWrap}>
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{formState.errMessage}</strong>
                {/* {SUCCESS} */}
              </Alert>
            ) : null}
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
            {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{formState.errMessage}</strong>
                {DELETE}
              </Alert>
            ) : null}
          </Grid>
          <div className={`${styles.assessmentTableWrap} ${styles.ScrollTable}`}>
            {newData.length !== 0 ? (
              <MaterialTable
                columns={columns}
                data={newData}
                pageSize={10}
                onButtonClick={onRowClick}
              /> 
            ) : (
            <Typography component="h5" variant="h3">
              This client does not have any OB360 assessments
            </Typography>
            )}
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default RaReportListing;
