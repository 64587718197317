import React, { useEffect, useState } from "react";
import styles from "./Client.module.css";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Button } from "../../../components/UI/Form/Button/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../components/UI/Form/Input/Input";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import {
  CREATE_CLIENT,
  UPDATE_CLIENT,
  CREATE_GUEST_CLIENT
} from "../../../graphql/mutations/Clients";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import * as routeConstant from "../../../common/RouteConstants";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import { useNavigate, useLocation } from "react-router-dom";
import logout from "../../Auth/Logout/Logout";
import {
  GET_CLIENTS,
  GET_CLIENT_AND_LATEST_REPORTS,
} from "../../../graphql/queries/Client";
// import { GET_PARTNER_SUBSCRIPTION } from "../../graphql/queries/PartnerSubscription";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import * as validations from "../../../common/validateRegex";
// import moment from "moment";
import { DELETE_CLIENT } from "../../../graphql/mutations/Clients";
import Cookies from "js-cookie";
import { GET_ADMIN_USER } from "../../../graphql/queries/User";
import ComputerIcon from "@mui/icons-material/Computer";
import {
  GET_PROSPECT_CLIENTS,
  GET_TARGET,
} from "../../../graphql/queries/Target";
import { RA_REPORT_DOWNLOAD } from "../../../config";
import { PUBLISH_REPORT } from "../../../graphql/mutations/PublishReport";
import PublishIcon from "@mui/icons-material/Publish";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ZIP_FILE } from "../../../graphql/mutations/Upload";
import PenTest from "../../PenTest/PenTest";
import { saveAs } from "file-saver";
import SearchIcon from "@mui/icons-material/Search";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import { GET_PARTNER_ID_USER } from "../../../graphql/queries/PartnerUser";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import * as msgConstant from "../../../common/MessageConstants";
import { useErrorBoundary } from "react-error-boundary";
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
export const Client: React.FC = (props: any) => {
  const { showBoundary } = useErrorBoundary();
  let moment = require("moment-timezone");
  const history = useNavigate();
  const location = useLocation();
  const [showBackdrop, setShowBackdrop] = useState<boolean>(true);
  const [param, setParam] = useState<any>(null);
  const [name, setName] = useState("");
  const [searchClient, setSearchClient] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [clientname, setclientname] = useState<any>("");
  const [newData, setNewData] = useState([]);
  const [prospectData, setProspectData] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [createFlag, setCreateFlag] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [ClientDataloaded, setClientDataloaded] = useState<any>();
  const [clientdatalength, setclientdatalength] = useState<any>();
  const [openGuestDialogBox,setOpenGuestDialogBox] = useState(false);
  const [guestClientData,setGuestClientData] = useState<any>([]);
  const [dataGuest,setDataGuest] = useState<any>();
  const partner = Cookies.get("ob_partnerData") || "";
  const usercookie = Cookies.getJSON("ob_user") || "";
  const user = JSON.parse(usercookie)
  let partnerdata = JSON.parse(partner);
  const [isGuestClientDisabled,setIsGuestClientDisabled] = useState(true)
  const [isGuestCreateError,setIsGuestCreateError] = useState(false)            //for create guest client dialog  error alert
  const [createGuestClient] = useMutation(CREATE_GUEST_CLIENT)
  const [getpartnerUserData] = useLazyQuery(GET_PARTNER_ID_USER, {
    onCompleted: (data: any) => {
      localStorage.setItem(
        "pg360PartnerId",
        data?.getPartnerUserDetails.edges[0].node.partnerId.pg360PartnerId
      );
      localStorage.setItem(
        "pg360UserId",
        data?.getPartnerUserDetails.edges[0].node.pg360UserId
      );
    },
    onError: (err:any)=>{
      sentry_error_catch(err,setShowBackdrop,setFormState)
    }
  });

  useEffect(() => {
    setParam(location?.state)
    if (location?.state) {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
  }, [location]);

  let userRole: any;
  if (Object.keys(user).length > 0) {
    userRole = user.isSuperuser == true ? "SuperUser" : "CompanyUser";
  }
  if (user.getUserDetails) {
    userRole =
      user.getUserDetails.edges[0].node.isSuperuser == true
        ? "SuperUser"
        : "CompanyUser";
  }

  useEffect(() => {
    if (userRole === "CompanyUser") {
      getClients({
        variables: {
          orderBy: "client_name",
          partnerId_PartnerName:
            partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
              .partnerName,
          client_type: "Client",
        },
      });
    }
  }, [userRole]);

  const [clientDeleted, setClientDeleted] = useState(false);

  const sortByDate = (a: any, b: any, order: any) => {
    if (!a.lastReportGenerated) return 1;
    if (!b.lastReportGenerated) return -1;

    const dateA = new Date(a.lastReportGenerated);
    const dateB = new Date(b.lastReportGenerated);

    if (order === "asc") {
      return dateA > dateB ? 1 : -1;
    }
    return dateA < dateB ? 1 : -1;
  };

  //Columns totable
  const CompanyUsercolumns = [
    { title: "Company Name", key: "name" },
    { title: "Scan Name", key: "targetName" },
    { title: "Last Scan Type", key: "scanType" },
    {
      title: "Last Report Generated on",
      key: "lastReportGenerated",
    },
    {
      key: "pentest",
      title: "",
      icon: <ComputerIcon id="pentest" />,
      tooltip: "Pen Test",
      displayIcon: (rowData: any) => true,
    },
    // {
    //   key: "advancedPentest",
    //   title: "",
    //   icon: <DescriptionIcon id="advanced-pentest" />,
    //   tooltip: "Advanced Pen Test",
    //   displayIcon: (rowData: any) => true,
    // },
    {
      key: "AddExternal",
      title: "",
      icon: <AddCircleIcon id="external" className={styles.CircleIcon} />,
      tooltip: "External Vulnerability",
      displayIcon: (rowData: any) => true,
    },
    {
      key: "Add",
      title: "",
      icon: <AddToPhotosIcon id="advanced-VA" className={styles.CircleIcon} />,
      tooltip: "Advanced Vulnerability",
      displayIcon: (rowData: any) => true,
    },
    {
      key: "EDGE",
      title: "",
      icon: <LanguageRoundedIcon id="external" className={styles.CircleIcon} />,
      tooltip: "EDGE Scan",
      displayIcon: (rowData: any) => true,
    },
    {
      key: "RA",
      title: "",
      icon: <VisibilityIcon />,
      tooltip: "View Assessment Details",
      displayIcon: (rowData: any) => true,
    },
  ];

  const ProspectUsercolumns = [
    { title: "Company Name", key: "client" },
    { title: "VT Report Status", key: "externalStatus" },
    // { title: "PT Report Status", key: "pentestStatus" },
  ];

  const SuperUsercolumns = [
    { title: "Company Name", key: "name" },
    {
      key: "RA",
      title: "",
      icon: <VisibilityIcon />,
      tooltip: "View Assessment Details",
      displayIcon: (rowData: any) => true,
    },
  ];

  const [isError, setIsError] = useState<any>({
    address: "",
    email: "",
    phoneNumber: "",
  });
  const [isNameError, setIsNameError] = useState<any>({
    name:false,
    msg:""
  });
  const [isEmailError, setIsEmailError] = useState<any>({
    email:false,
    msg:""
  });

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [deleteClient] = useMutation(DELETE_CLIENT);
  const [publishReport] = useMutation(PUBLISH_REPORT);
  const [uploadFile] = useMutation(ZIP_FILE);

  let contactIdArray: any = [];
  const [getGuestClients] = useLazyQuery(GET_CLIENTS,{
    fetchPolicy: "network-only"
  });
  const [getClients, { data: ipData, loading: ipLoading }] = useLazyQuery(
    GET_CLIENTS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data: any) => {
        console.log("data",data);
        if (userRole === "CompanyUser") {
          createTableDataObject(data.getClient.edges);
        }
        if (userRole === "SuperUser") {
          createTableDataObjectAdmin(data.getClient.edges);
        }
      },
      onError: (err:any) => {
        sentry_error_catch(err,setShowBackdrop,setFormState)
        // logout();
      },
    }
  );
  const [
    getProsClients,
    { data: ProspectusClientData, loading: ProspectusClientLoading },
  ] = useLazyQuery(GET_PROSPECT_CLIENTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      setShowBackdrop(false);
      createProspectTableDataObject(data.getCompanyData[0].data);
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
      // logout()
    },
  });
  const [
    getClientsAndReports,
    { data: ClientReportData, loading: ClientReportLoading },
  ] = useLazyQuery(GET_CLIENT_AND_LATEST_REPORTS, {
    fetchPolicy: "cache-and-network",
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
      // logout();
      // history(routeConstant.DASHBOARD);
    },
  });

  let column: any;
  if (userRole === "CompanyUser") {
    column = CompanyUsercolumns;
  } else {
    column = SuperUsercolumns;
  }

  useEffect(() => {
    if (
      param == null ||
      (param == undefined && partner !== null && user !== null)
    ) {
      let userdata = user;
      localStorage.setItem(
        "user",
        JSON.stringify(userdata?.data?.getUserDetails.edges[0].node)
      );
      localStorage.setItem(
        "partnerData",
        JSON.stringify(partnerdata?.data?.getPartnerUserDetails.edges[0].node)
      );
      getpartnerUserData({
        variables: {
          userId:
            partnerdata?.data?.getPartnerUserDetails.edges[0].node.userId
              .username,
        },
      });
    }
  }, [partner]);
  useEffect(() => {
    if((name.trim() !== "" && name.length > 0 ) && (email.trim() !== "" && email.length > 0 ) && (email && validations.EMAIL_REGEX.test(email))){
      setIsGuestClientDisabled(false)
    }else{
      setIsGuestClientDisabled(true)
    }
  },[name,email])
 
  useEffect(() => {
    if (ipData != undefined) {
      createTableDataObject(ipData.getClient.edges);
    }
    if(dataGuest != undefined){
      console.log("guesDta",dataGuest.data.getClient.edges)
      createGuestClientTableObj(dataGuest?.data?.getClient?.edges)
    }
  }, [ClientReportData,dataGuest]);
  console.log("param",param)

  useEffect(() => {
    // On Login from tool
    if (Cookies.getJSON("ob_session")) {
      if (
        param == null ||
        (param == undefined &&
          typeof partner !== "string" &&
          partner !== null &&
          user !== null)
      ) {
        
        if (partnerdata.data != null) {
          if (
            partnerdata.data.getPartnerUserDetails.edges[0].node.hasOwnProperty(
              "partnerId"
            )
          ) {
            getClients({
              variables: {
                orderBy: "client_name",
                partnerId_PartnerName:
                  partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
                    .partnerName,
                client_type: "Client",
              },
            });
            getGuestClients({
              variables: {
                orderBy: "client_name",
                partnerId_PartnerName:
                  partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
                    .partnerName,
                client_type: "Guest",
              },
            }).then((data:any) => {
                console.log("GuestData",data)
                setDataGuest(data);
                createGuestClientTableObj(data?.data?.getClient?.edges)
            }).catch((err:any)=>{
               sentry_error_catch(err,setShowBackdrop,setFormState)
            })
            getProsClients({
              variables: {
                pgPartnerId:
                  partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
                    .id,
                // client_type: "Prospect"
              },
            });
            getClientsAndReports({
              variables: {
                partnerId:
                  partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
                    .id,
              },
            });
          }
        }
      }
      if (param !== null && param !== undefined && param.partner_id) {
        getClients({
          variables: {
            orderBy: "client_name",
            partnerId_PartnerName: param.partner_id,
            client_type: "Client",
          },
        });
        getGuestClients({
          variables: {
            orderBy: "client_name",
            partnerId_PartnerName:param.partner_id,
            client_type: "Guest",
          },
        }).then((data:any) => {
            console.log("GuestData1",data)
            setDataGuest(data);
            createGuestClientTableObj(data?.data?.getClient?.edges)
        }).catch((err:any)=>{
               sentry_error_catch(err,setShowBackdrop,setFormState)
          })
      }
      if (param && param.clientInfo) {
        getClients({
          variables: {
            orderBy: "client_name",
            partnerId_PartnerName: param.clientInfo.partnerId,
            client_type: "Client",
          },
        });
        getGuestClients({
          variables: {
            orderBy: "client_name",
            partnerId_PartnerName:param.clientInfo.partnerId,
            client_type: "Guest",
          },
        }).then((data:any) => {
            console.log("GuestData2",data)
            setDataGuest(data);
            createGuestClientTableObj(data?.data?.getClient?.edges)
        }).catch((err:any)=>{
               sentry_error_catch(err,setShowBackdrop,setFormState)
            })
        if (partner != "") {
          getClientsAndReports({
            variables: {
              partnerId:
                partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
                  .id,
            },
          });
        }
      }
      if (param && param !== null && param.formState) {
        setFormState(param.formState);
      }
      if (userRole != "SuperUser") {
        let partnerData = JSON.parse(partner);
        getProsClients({
          variables: {
            // orderBy: "client_name",
            pgPartnerId:
              partnerData.data.getPartnerUserDetails.edges[0].node.partnerId.id,
            // client_type: "Prospect",
          },
        });
      }
      if (userRole === "SuperUser") {
        getProsClients({
          variables: {
            // orderBy: "client_name",
            pgPartnerId: param?.id,
            // client_type: "Prospect",
          },
        });
      }
    } else {
      logout();
    }
  }, [param]);
  let partnerData = partner ? JSON.parse(partner) : "";

  const createTableDataObjectAdmin = (data: any) => {
    let arr: any = [];
    data.forEach((element: any) => {
      let obj: any = {};
      obj["email"] = !element.node.emailId ? "-" : element.node.emailId;
      obj["name"] = element.node.clientName;
      obj["phone"] = !element.node.mobileNumber
        ? "-"
        : element.node.mobileNumber;
      obj["clientId"] = element.node.id;
      obj["partnerId"] = element.node.partner.partnerName;
      obj["createdOn"] = moment(element.node.createdDate).format(
        "MM/DD/YYYY hh:mm a"
      );
      obj["subscription"] = element.node.subscription;
      arr.push(obj);
    });

    setNewData(arr);
    setShowBackdrop(false);
  };

  const handlePublishchange = (event: any, rowData: any) => {
    // if (event.target.checked !== undefined) {
    setShowBackdrop(true);
    publishReport({
      variables: {
        input: {
          client: parseInt(rowData.clientId),
          targetName: rowData.targetName,
          flagStatus: true,
        },
      },
    })
      .then((response: any) => {
        setShowBackdrop(false);
        if (
          response.data.publishedReport.success ==
          "Report Published Successfully "
        ) {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: "Report Published Successfully !!",
          }));
        } else {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: " Report Un-Published Successfully !!",
          }));
        }
      })
      .catch((err: any) => {
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };
  const createProspectTableDataObject = (data: any) => {
    let arr: any = [];
    data.forEach((element: any) => {
      let obj: any = {};
      obj["client"] = element.clientName;
      obj["clientId"] = element.clientId;
      obj["external"] = element.external;
      obj["externalStatus"] =
        element.externalStatus != "" ? element.externalStatus : "-";
      obj["pentestStatus"] =
        element.pentestStatus != "" ? element.pentestStatus : "-";
      obj["pentest"] = element.pentest;
      obj["externalId"] = element.externalId;
      obj["pentestId"] = element.pentestId;
      arr.push(obj);
    });
    setProspectData(arr);
  };

  const createTableDataObject = (data: any) => {
    console.log("data",data);
    let arr: any = [];
    data.forEach((element: any) => {
      if (
        element.node.subscription === "Yes" &&
        element.node.activeStatus == 1
      ) {
        let obj: any = {};
        obj["email"] = !element.node.emailId ? "-" : element.node.emailId;
        obj["name"] = element.node.clientName;
        obj["phone"] = !element.node.mobileNumber
          ? "-"
          : element.node.mobileNumber;
        obj["clientId"] = element.node.id;
        obj["partnerId"] = element.node.partner.partnerName;
        obj["createdOn"] = moment(element.node.createdDate).format(
          "MM/DD/YYYY hh:mm a"
        );
        obj["subscription"] = element.node.subscription;
        if (ClientReportData) {
          for (let i in ClientReportData.reportForPg) {
            ClientReportData.reportForPg[i].data.forEach((data: any) => {
              if (element.node.id === data.clientId.toString()) {
                if (data.targetCreationDate !== "") {
                  obj["lastReportGenerated"] = moment(data.targetCreationDate)
                    .tz(
                      partnerData.data.getPartnerUserDetails.edges[0].node
                        .partnerId.tZone
                    )
                    .format("MM/DD/YYYY hh:mm a");
                }
                if (data.scanType === "Pentest") {
                  obj["scanType"] = "Pen Test";
                } else if (data.scanType === "Advanced") {
                  obj["scanType"] = "Advanced VA";
                } else if (data.scanType === "External") {
                  obj["scanType"] = "External VA";
                } else if (data.scanType === "MAIN EDGE") {
                  obj["scanType"] = "EDGE";
                }else {
                  obj["scanType"] = data.scanType;
                }

                obj["targetName"] = data.targetName;
              }
            });
          }
        }
        arr.push(obj);
      }
    });
    arr.sort((a: any, b: any) => {
      if (!a.lastReportGenerated) return 1;
      if (!b.lastReportGenerated) return -1;
      return (
        new Date(b.lastReportGenerated).valueOf() -
        new Date(a.lastReportGenerated).valueOf()
      );
    });
    setNewData(arr);
    setClientDataloaded(true);
  };
  const createGuestClientTableObj = (data:any) => {
    console.log("guestOBJ",data)
    let arr:any = [];
    if(data !== undefined && data.length > 0){
      data.forEach((obj:any) => {
        if(obj.node.activeStatus == 1 && obj.node.subscription === "Yes"){
          let guestObj:any = {}
          guestObj["email"] = !obj.node.emailId ? "-" : obj.node.emailId;
          guestObj["name"] = obj.node.clientName;
          guestObj["phone"] = !obj.node.mobileNumber
            ? "-"
            : obj.node.mobileNumber;
          guestObj["clientId"] = obj.node.id;
          guestObj["partnerId"] = obj.node.partner.partnerName;
          guestObj["createdOn"] = moment(obj.node.createdDate).format(
            "MM/DD/YYYY hh:mm a"
          );
          guestObj["subscription"] = obj.node.subscription
          if (ClientReportData) {
            for (let i in ClientReportData.reportForPg) {
              ClientReportData.reportForPg[i].data.forEach((data: any) => {
                if (obj.node.id === data.clientId.toString()) {
                  if (data.targetCreationDate !== "") {
                    guestObj["lastReportGenerated"] = moment(data.targetCreationDate)
                      .tz(
                        partnerData.data.getPartnerUserDetails.edges[0].node
                          .partnerId.tZone
                      )
                      .format("MM/DD/YYYY hh:mm a");
                  }
                  if (data.scanType === "Pentest") {
                    guestObj["scanType"] = "Pen Test";
                  } else if (data.scanType === "Advanced") {
                    guestObj["scanType"] = "Advanced VA";
                  } else if (data.scanType === "External") {
                    guestObj["scanType"] = "External VA";
                  } else {
                    guestObj["scanType"] = data.scanType;
                  }
  
                  guestObj["targetName"] = data.targetName;
                }
              });
            }
          }
          arr.push(guestObj)
        }
      })
    }
    setGuestClientData(arr);
  }

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  const handleClickEdit = (rowData: any) => {
    handleAlertClose();
    history(routeConstant.CLIENT_FORM_EDIT + rowData.clientId, {
      state: { rowData },
    });
  };
  const handleClickDelete = (rowData: any) => {
    handleAlertClose();
    setClientDeleted(false);
    setShowBackdrop(true);
    deleteClient({
      variables: {
        id: rowData.clientId,
      },
    })
      .then((res: any) => {
        setShowBackdrop(false);
        if (res.data.deleteClient.status === "Client is deleted") {
          setClientDeleted(true);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: true,
            isFailed: false,
            errMessage: "  " + rowData.name + "  ",
          }));
        }
        if (res.data.deleteClient.status === "Client is not deleted") {
          setShowBackdrop(false);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: " Failed to Delete Client " + rowData.name + " ",
          }));
        }
      })
      .catch((err) => {
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const onRowClick = async (rowData: any, key: any) => {
    handleAlertClose();
    let data: any = { clientInfo: rowData, partnerId: partner };
    if (key === "RA") {
      // setShowBackdrop(true)
      if (Cookies.getJSON("ob_session")) {
        history(routeConstant.RA_REPORT_LISTING, { state: { data } });
      } else {
        logout();
      }
    }
    if (key === "Edit") {
      handleClickEdit(rowData);
    }
    if (key === "Delete") {
      handleClickDelete(rowData);
    }
    if (key === "Add") {
      if (Cookies.getJSON("ob_session")) {
        let data = {
          clientInfo: rowData,
          previousPage: "client",
        };
        history(routeConstant.TARGET, { state: { data } });
      } else {
        logout();
      }
    }
    if (key === "AddExternal") {
      if (Cookies.getJSON("ob_session")) {
        let data = {
          clientInfo: rowData,
          previousPage: "client",
        };
        history(routeConstant.EXTERNAL_TARGET, { state: { data } });
      } else {
        logout();
      }
    }
    if (key === "advancedPentest") {
      if (Cookies.getJSON("ob_session")) {
        let data = {
          clientInfo: rowData,
          previousPage: "client",
        };
        history(routeConstant.ADVANCED_PEN_TEST, { state: { data } });
      } else {
        logout();
      }
    }
    if (key === "pentest") {
      if (Cookies.getJSON("ob_session")) {
        let data = {
          clientInfo: rowData,
          previousPage: "client",
        };
        history(routeConstant.PEN_TEST, { state: { data } });
      } else {
        logout();
      }
    }
    if (key === "downloadExternal") {
      if (Cookies.getJSON("ob_session")) {
        setShowBackdrop(true);
        let intTargetId = parseInt(rowData.externalId);
        const DocUrl = `${RA_REPORT_DOWNLOAD}?cid=${rowData.clientId}&tid=${intTargetId}`;
        await fetch(DocUrl, {
          method: "GET",
        })
          .then((response: any) => {
            response
              .blob()
              .then((blobData: any) => {
                saveAs(blobData, rowData.client);
                setShowBackdrop(false);
              })
              .catch((err: any) => {
                sentry_error_catch(err,setShowBackdrop,setFormState)
              });
          })
          .catch((err) => {
            sentry_error_catch(err,setShowBackdrop,setFormState)
          });
      } else {
        logout();
      }
    }
    if (key === "downloadPenTest") {
      if (Cookies.getJSON("ob_session")) {
        setShowBackdrop(true);
        let intTargetId = parseInt(rowData.pentestId);
        const DocUrl = `${RA_REPORT_DOWNLOAD}?cid=${rowData.clientId}&tid=${intTargetId}`;
        await fetch(DocUrl, {
          method: "GET",
        })
          .then((response: any) => {
            response.blob().then((blobData: any) => {
              saveAs(blobData, rowData.client);
              setShowBackdrop(false);
            });
          })
          .catch((err:any) => {
            sentry_error_catch(err,setShowBackdrop,setFormState)           
          });
      } else {
        logout();
      }
    }
    if (key === "ViewPenTest") {
      if (Cookies.getJSON("ob_session")) {
        let data = { clientInfo: rowData, type: "Pentest" };
        history(routeConstant.VIEW_PROSPECT, { state: { data } });
      } else {
        logout();
      }
    }
    if (key === "ViewExternal") {
      if (Cookies.getJSON("ob_session")) {
        let data = { clientInfo: rowData, type: "External" };
        history(routeConstant.VIEW_PROSPECT, { state: { data } });
      } else {
        logout();
      }
    }
    if (key === "EDGE") {
      if (Cookies.getJSON("ob_session")) {
        let data = {
          clientInfo: rowData,
          previousPage: "client",
        };
        history(routeConstant.EDGE_SCAN, { state: { data } });
      } else {
        logout();
      }
    }
  };

  // if (ipLoading || showBackdrop) return <SimpleBackdrop />;
  const handleDownload = async (rowData: any) => {
    handleAlertClose();
    if (Cookies.getJSON("ob_session")) {
      setShowBackdrop(true);
      let intTargetId = parseInt(rowData.targetId);
      const DocUrl = `${RA_REPORT_DOWNLOAD}?cid=${rowData.clientId}&tid=${intTargetId}`;
      await fetch(DocUrl, {
        method: "GET",
      })
        .then((response: any) => {
          response.blob().then((blobData: any) => {
            saveAs(blobData, rowData.target);
            setShowBackdrop(false);
          });
        })
        .catch((err:any) => {
          sentry_error_catch(err,setShowBackdrop,setFormState)
        });
    } else {
      logout();
    }
  };
  const handleUpload = (rowData: any) => {
    if (selectedFile[rowData.targetId]) {
      setShowBackdrop(true);
      let idCardBase64 = "";
      getBase64(selectedFile[rowData.targetId], (result: any) => {
        idCardBase64 = result;
        var res = result.slice(result.indexOf(",") + 1);
        uploadFile({
          variables: {
            input: {
              client: parseInt(param.clientInfo.clientId),
              targetName: rowData.target,
              file: res,
            },
          },
        })
          .then((response: any) => {
            if (
              response.data.uploadZipFile.success === "File Uploaded Failed"
            ) {
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_FILE_UPLOAD_FAILED,
              }));
              setSelectedFile({});
              setShowBackdrop(false);
            } else {
              setFormState((formState) => ({
                ...formState,
                isSuccess: true,
                isUpdate: false,
                isDelete: false,
                isFailed: false,
                errMessage: "File Uploaded Successfully !!",
              }));
              setSelectedFile({});
              setShowBackdrop(false);
            }
          })
          .catch((err: Error) => {
            sentry_error_catch(err,setShowBackdrop,setFormState)
          });
      });
    }
  };
  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (err:any) {
        sentry_error_catch(err,setShowBackdrop,setFormState)
      };
    }
  };

  const handleSearch = (event: any) => {
    setSearchClient(true);
    getClients({
      variables: {
        orderBy: "client_name",
        client_type: "Client",
        partnerId_PartnerName:
          partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
            .partnerName,
        clientName: clientname,
      },
    });
  };

  const handleBack = () => {
    setclientname("");
    getClients({
      variables: {
        orderBy: "client_name",
        client_type: "Client",
        partnerId_PartnerName:
          partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
            .partnerName,
        clientName: "",
      },
    });
    setTimeout(() => {
      setSearchClient(false);
    }, 500);
  };
  const OpenGuestDialogBoxHandler = () => {
    setOpenGuestDialogBox(true)
  }
  const handleInputErrors = () => {
    let foundErrors = false;
    if (!name) {
      let err = msgConstant.GUEST_CLIENT_NAME_EMPTY;
      setIsNameError((error: any) => ({
        name: true,
        msg:err
      }));
      foundErrors = true;
    }
    // else if(/[^a-zA-Z\-\ ]/.test(name)){
    //   setIsNameError({
    //     name:true,
    //     msg:"Invalid user name"
    //   })
    //   foundErrors = true;
    // }
    else if (!email) {
      let err = msgConstant.GUEST_CLIENT_EMAIL_EMPTY;
      setIsEmailError((error: any) => ({
        email: true,
        msg:err
      }));
      foundErrors = true;
    }
    else if (email && !validations.EMAIL_REGEX.test(email)) {
      let errors = msgConstant.GUEST_CLIENT_EMAIL_INVALID;
      setIsEmailError((error: any) => ({
        email:true,
        msg: errors,
      }));
      foundErrors = true;
    }

    return foundErrors;
  };
  const addGuestClient = () => {
    try{
    if(!handleInputErrors()){
      setShowBackdrop(true)
      createGuestClient({
        variables:{
          input: {
            "clientName": name,
            "clientType": "Guest",
            // "pg360ClientId": 34534 + 1,
            "emailId":email,
            "pg360partnerId": parseInt(localStorage.getItem("pg360PartnerId") || ""),
            "logoPath": "",
            "activeStatus": "1",
            "subscription":"Yes",
            "mailSend": false
          }
        }
      }).then((data) => {
      if(data.data.createClient.clientField){
        setShowBackdrop(false)
        setFormState((formState) => ({
          ...formState,
          isSuccess: true,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          errMessage: `  ${name}  `,
        }));
        const obj:any = {};
        console.log("data",data)
        obj["email"] = !data.data.createClient.clientField.emailId ? "-" : data.data.createClient.clientField.emailId;
        obj["name"] = data.data.createClient.clientField.clientName;
        obj["phone"] = !data.data.createClient.clientField.mobileNumber
          ? "-"
          : data.data.createClient.clientField.mobileNumber;
        obj["clientId"] = data.data.createClient.clientField.id;
        obj["partnerId"] = data.data.createClient.clientField.partner.partnerName;
        obj["createdOn"] = moment(data.data.createClient.clientField.createdDate).format(
          "MM/DD/YYYY hh:mm a"
        );
        obj["subscription"] = data.data.createClient.clientField.subscription
        obj["lastReportGenerated"] = "";
        obj["ScanType"] = "";

        setGuestClientData((guestClientData:any) => [...guestClientData,obj])
        // closeDialogBox()
        setOpenGuestDialogBox(false);
        setName("");
        setIsNameError({});
        setEmail("");
        setIsEmailError({});
        setIsGuestClientDisabled(true);
        setIsGuestCreateError(false);
      }else{
        console.log("Error in guest client creation")
        setIsGuestCreateError(true)
        sentry_error_catch(`Error in guest client creation ${JSON.stringify(data)}`,setShowBackdrop,setFormState)
      }
      }).catch((err:any) => {
        setIsGuestCreateError(true)
        sentry_error_catch(err,setShowBackdrop,setFormState)
      })
    }
  }catch(error){
      showBoundary(error)
    }
  }
  const handleChange = (event:any) => {
    if(event.target.name === "Name"){
      setName(event.target.value)
      if(event.target.value.trim() === "" || event.target.value.length < 0 ){
       setIsNameError({
         name:true,
         msg:"User name is Required"
       })
      }
      // else if(/[^a-zA-Z\-\ ]/.test(event.target.value)){
      //   setIsNameError({
      //     name:true,
      //     msg:"Invalid user name"
      //   })
      // }
      else{
        setIsNameError({
          name:false,
          msg:""
        })
      }
    }
    if(event.target.name === "email"){
      setEmail(event.target.value)
      if(event.target.value.trim() === "" || event.target.value.length < 0 ){
        setIsEmailError({
          email:true,
          msg:"Email is Required"
        })
       }
       else if (event.target.value && !validations.EMAIL_REGEX.test(event.target.value)) {
        let errors = msgConstant.GUEST_CLIENT_EMAIL_INVALID;
        setIsEmailError((error: any) => ({
          email:true,
          msg:errors
        }));
      }else{
        setIsEmailError({
          email:false,
          msg:""
        })
      }
    }
    // if((name.trim() === "" || name.length < 0 ) && (email.trim() === "" || email.length < 0 ) && (email && !validations.EMAIL_REGEX.test(email))){
    //   setIsGuestClientDisabled(false)
    // }
  }
  const closeDialogBox = () => {
    setOpenGuestDialogBox(false);
    setName("");
    setIsNameError({});
    setEmail("");
    setIsEmailError({});
    setIsGuestClientDisabled(true)
    setIsGuestCreateError(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {showBackdrop ? <SimpleBackdrop /> : null}
      <Grid>
        {/* {showBackdrop ? <SimpleBackdrop /> : null} */}
        <Grid container className={styles.backToListButtonPanel}>
          <Grid item xs={12} md={12} className={styles.backToListButton}>
            <div className={styles.ButtonGroup1}>
              <div className={styles.FilterInputgotolist}>
                {userRole === "SuperUser" ? (
                  <Button
                    className={styles.BackToButton}
                    variant={"contained"}
                    onClick={() => {
                      history(routeConstant.ADD_PARTNER);
                    }}
                    color="secondary"
                    data-testid="cancel-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/icons/svg-icon/back-list.svg"
                      }
                      alt="user icon"
                    />
                    &nbsp; Back to List
                  </Button>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
        {formState.isSuccess ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Client<strong>{formState.errMessage}</strong>
            {SUCCESS}
          </Alert>
        ) : null}
        {formState.isUpdate ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Client<strong>{formState.errMessage}</strong>
            {UPDATE}
          </Alert>
        ) : null}
        {(formState.isFailed && isGuestCreateError===false) ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {FAILED}
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
        {formState.isDelete ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
            {DELETE}
          </Alert>
        ) : null}
        <DialogBox
          open={openGuestDialogBox}
          handleOk={addGuestClient}
          handleCancel={closeDialogBox}
          buttonOk={"Create"}
          buttonCancel={"Cancel"}
          classes={{
            root: styles.MainOfficeDialogRoot,
            container: styles.MainOfficeDialogboxContainer,
            paper: styles.MainOfficeDialogboxPaper,
            scrollPaper: styles.MainOfficeScrollPaper,
          }}
          disabled={isGuestClientDisabled}
        >
          {showBackdrop ? <SimpleBackdrop /> : null}
          <div className={styles.DialogBoxTitle}>
            <Typography component="h1" variant="h1">
              {"Guest Client"}
            </Typography>
          </div>
          <div>
          {(formState.isFailed && isGuestCreateError) && (<Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {FAILED}
            <strong>{formState.errMessage}</strong>
          </Alert>)}
          <Grid container spacing={3}>
            <Grid item xs={4} classes={{ item: styles.MuiGridItem }}>
             <Typography component="h3" variant="h3"> Name :</Typography>
            </Grid>
            <Grid item xs={8} classes={{ item: styles.ReactInput1 }}>
              <Input
                type="text"
                label="Name"
                name="Name"
                value={name}
                onChange={handleChange}
                required
                error={isNameError.name}
                helperText={isNameError.msg}
              >
                Name
              </Input>
            </Grid>
            <Grid item xs={4} classes={{ item: styles.MuiGridItem }}>
             <Typography component="h3" variant="h3"> Email :</Typography>
            </Grid>
            <Grid
              item
              xs={8}
              // className={rowData ? styles.disfield : styles.test}
              classes={{ item: styles.ReactInput2 }}
            >
              <Input
                type="text"
                label="Email"
                name="email"
                value={email}
                // disabled={rowData}
                required
                onChange={handleChange}
                error={isEmailError.email}
                helperText={isEmailError.email ? isEmailError.msg :false}
              >
                E-mail
              </Input>
            </Grid>
            </Grid>
          </div>
        </DialogBox>
        {userRole != "SuperUser" ? (
          <Grid item xs={12} className={styles.ActionButtons}>
            <Grid item xs={12} md={4} className={styles.searchbarInput}>
              <Input
                id="client-search"
                type="text"
                label="Name*"
                name="Name"
                value={clientname || ""}
                onChange={(event: any) => setclientname(event.target.value)}
                error={isError.name}
                helperText={isError.name}
              >
                Client Name
              </Input>
            </Grid>
            <Grid item xs={12} md={5} className={styles.searchbarButton}>
              <Button
                className={styles.ActionButton}
                variant={"contained"}
                color="primary"
                onClick={handleSearch}
                id="search-button"
              >
                <SearchIcon className={styles.EditIcon} /> &nbsp; Search
              </Button>
              <Button
                className={styles.borderLess}
                variant={"contained"}
                onClick={handleBack}
                color="primary"
                id="cancel-button"
              >
                RESET
              </Button>
            </Grid>
            <Grid item xs={12} md={3} classes={{ item: styles.GuestCientButton }}>
              <Button
                id="create-assessment"
                color="primary"
                variant="contained"
                onClick={OpenGuestDialogBoxHandler}
              >
                <AddCircleIcon className={styles.EditIcon} />
                &nbsp; Guest Client
              </Button>
            </Grid>
          </Grid>         
        ) : (
          ""
        )}
        <Typography component="h1" variant="h1" classes={{h1:styles.title}}>
          {"Enrolled Client"}
        </Typography>
        <Paper className={styles.paper}>
          {ipLoading ? <SimpleBackdrop /> : null}
          <div className={styles.ScrollTable}>
            {newData.length !== 0 ? (
              <MaterialTable
                columns={column}
                data={newData}
                pageSize={10}
                onButtonClick={onRowClick}
              />
            ) : !showBackdrop &&
              ClientDataloaded !== undefined &&
              ClientDataloaded &&
              newData.length == 0 ? (
              !searchClient ? (
                <Typography component="h5" variant="h3">
                  You don't have any client subscribed for OB360
                </Typography>
              ) : (
                <Typography component="h5" variant="h3">
                  Data not available
                </Typography>
              )
            ) : null}
          </div>
        </Paper>
        {guestClientData.length !== 0 ? ( 
        <React.Fragment>
        <Typography component="h1" variant="h1" classes={{h1:styles.title}}>
          {"Guest Client"}
        </Typography>
        <Paper className={styles.paper}>
          <div className={styles.ScrollTable}>
            {guestClientData.length !== 0 ? (
              <MaterialTable
                columns={column}
                data={guestClientData}
                pageSize={10}
                onButtonClick={onRowClick}
              />
            ) : 
              !showBackdrop &&
              guestClientData.length == 0 ? (
              !searchClient ? (
                <Typography component="h5" variant="h3">
                  You don't have any Guest Client subscribed for OB360
                </Typography>
              ) : (
                <Typography component="h5" variant="h3">
                  Data not available
                </Typography>
              )
            ) : 
            null}
          </div>
        </Paper>
        </React.Fragment>
        ):
        null}
      </Grid>
    </React.Fragment>
  );
};

export default Client;
